<template>
  <div class="page page-xprogrammatic-all-creatives">
    <PageHeader
    :icon="$t('CREATIVE.ICON')"
    :title="$t('CREATIVE.TITLES')"
    >

      <template slot="nav">
        <small><strong>{{$tc('CREATIVE.TITLE_COUNT',count)}}</strong></small>
      </template>

      <!-- <v-menu
        offset-y
        nudge-bottom="5px"
        :close-on-click="true"
      >
        <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
          <v-tooltip bottom transition="none">
            <template v-slot:activator="{ on: onTip, attrs: attrsTip }">
              <v-btn
                fab
                small
                depressed
                color="primary"
                v-bind="{...attrsMenu, ...attrsTip}"
                v-on="{...onMenu, ...onTip}"
              >
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
            </template>
            {{ $t('COMMON.MORE_ACTIONS') }}
          </v-tooltip>
        </template>

        <v-list nav dense>
          <v-list-item link @click="newItem">
            <v-list-item-icon><v-icon size="20">mdi-plus</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-capitalize">
                <strong>{{ $t('COMMON.NEW') }}</strong>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->

      <v-tooltip bottom transition="none">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            fab
            small
            depressed
            color="success"
            class="ml-2"
            v-bind="attrs"
            v-on="on"
            @click="newItem"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </template>
        {{ $t('COMMON.ADD') }}
      </v-tooltip>


    </PageHeader>


    <Side
      component="forms/creative/compact/FormCreativeCompact"
      :ids="(selected || []).map(v => v.id)"
      :value="showEdit"
      @close="closeEdit"
      @create="goToItem"
      @update="loadItems"
      @remove="removeItemFromCompact"
    />
    <!-- <Dialog
      :icon="selected.length ? 'mdi-pencil' : 'mdi-plus-thick'"
      :title="$t('COMMON.EDIT') + ': ' + $tc('USER_PERMISSION.TITLE_COUNT', selected.length)"
      component="form/FormAssignUserPermission"
      :items="selected || []"
      :props="{type:'creative'}"
      :value="showDialogPermissionEdit"
      @close="showDialogPermissionEdit = false"
      @update="loadItems"
      @select="selectItems"
    /> -->
    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="title"
      @confirm="confirmRemoveItems"
      @close="showDialogRemove = false"
      @select="selectItems"
    />
    <List
      :items="items"
      :count="count"
      :offset="offset"
      :limit="limit"
      :pagination="true"
      :loading="loading"
      :selected="selected"
      :selectable="false"
      mode="col"
      cols="6"
      sm="6"
      md="4"
      lg="3"
      xl="2"
      @applyFilters="applyFilters"
      @resetFilters="resetFilters"
      @select="selectItems"
      @page="changePage"
      @dblclick="editItem"
      @reload="searchItems"
    >
      <template v-slot:header="">
        <div class="col-4">
          <v-text-field
            rounded
            ref="where.title"
            hide-details
            outlined
            dense
            clearable
            prepend-inner-icon="mdi-magnify"
            :placeholder="$t('COMMON.SEARCH')"
            @keyup="searchItems"
            @click:clear="searchItems"
          />
        </div>
        <div class="col-2">
          <v-select
            class="none"
            multiple
            :items="$t('CREATIVE.TYPE_ITEMS')"
            item-text="text"
            item-value="value"
            ref="where.type"
          />
        </div>
      </template>

      <!-- <template v-slot:filters="">
        <div>
          <p class="mb-0 primary--text">{{ $t('INPUT.STATUS') }}</p>
          <v-select
            class="none"
            v-model="status"
            multiple
            :items="statuses"
            item-text="text"
            item-value="value"
            ref="where.status"
          />
          <template v-for="(item,i) in statuses">
            <v-checkbox
              v-model="status"
              :key="'sta'+i"
              :label="item.text"
              :value="item.value"
              hide-details
              class="mt-0"
            />
          </template>
        </div>

        <div class="mt-6">
          <p class="mb-0 primary--text">{{ $t('INPUT.FILEFORMAT') }}</p>
          <v-select
            class="none"
            v-model="fileformat"
            multiple
            :items="formats"
            item-text="text"
            item-value="value"
            ref="where.fileformat"
          />
          <template v-for="(item,i) in formats">
            <v-checkbox
              v-model="fileformat"
              :key="'fila'+i"
              :label="item.text"
              :value="item.value"
              hide-details
              class="mt-0"
            />
          </template>
        </div>

      </template> -->

      <template v-slot:empty="">
        <div class="ml-10 mt-6 text-center">
          <div class="text-h5 mt-10 mb-4">{{$t('LIST.NO_RESULT')}}</div>
          <template v-if="$queryFilters().count() > 0">
            <div><small class="grey--text">{{$t('LIST.SUGGESTION')}}</small></div>
            <div><a class="info--text" @click="clearFilters">{{$t('LIST.CLEAR_FILTER')}}</a></div>
            <!-- <div><a class="info--text" @click="newItem">{{$t('LIST.CREATE_ITEM')}}</a></div> -->
          </template>
        </div>
      </template>

      <template v-slot:selections="">
        <v-col cols="12" class="pt-0 pl-0">
          <div><small><strong>{{$tc('COMMON.SELECTED', +selected.length)}}</strong></small></div>

          <v-btn-toggle dark rounded>
            <!-- <v-btn
              small
              rounded
              depressed
              color="primary"
              @click="showEdit = true"
            >
              <v-icon size="20" class="mr-2">mdi-pencil</v-icon>
              {{ $t('COMMON.EDIT') }}
            </v-btn> -->
            <v-btn
              small
              rounded
              depressed
              color="error"
              @click="showDialogRemove = true"
            >
              <v-icon size="20" class="mr-2">mdi-delete</v-icon>
              {{ $t('COMMON.REMOVE') }}
            </v-btn>
            <v-btn
              small
              rounded
              depressed
              color="secondary custom black--text"
              @click="selectItems([])"
            >
              <v-icon size="20" class="mr-2" color="black">mdi-close</v-icon>
              {{ $t('COMMON.CANCEL') }}
            </v-btn>
          </v-btn-toggle>
        </v-col>
      </template>

      <template v-slot:item-col="item">
          <v-card color="elevation-0" :ripple="true" :class="['cursor-pointer', Date.now() - +new Date(item.updated_at) < 5000 ? 'highlight' : '']">
            <v-system-bar dark color="primary pa-4 pl-2">
              <v-icon class="mr-2" size="20">{{$t('CREATIVE.ICON')}}</v-icon>
              <strong class="text-large text-ellipsis">{{item.title}}</strong>
              <v-spacer/>
            </v-system-bar>

            <div class="pa-4">
              <div class="mb-2">
                <template v-if="item.content">
                  <v-chip x-small flat color="primary" class="mr-2" v-if="item.content.type">{{item.content.type}}</v-chip>
                  <small v-if="item.content.width">{{item.content.width}} x {{item.content.height}}</small>
                </template>
                  <!-- <small v-if="item.channel"><v-icon size="14" class="mr-2">mdi-lock</v-icon><span>{{item.channel.title}} - {{item.type}}</span></small><br /> -->
                  <!-- <div v-for="(k, i) in Object.keys(item.content)" :key="item.id+i+'_parm'">
                  <small>{{k}}: <strong>{{item.content[k]}}</strong></small>
                </div> -->
              </div>
              <div class="creative-example" style="height:200px; border:dashed 1px #ddd; background:#eee;" v-if="item.content">
                <BannerEditorPreview :values="item" />
              </div>
            </div>
            <v-card-actions class="pa-4">
              <v-btn icon @click.stop="removeItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
              <v-btn
                icon
                @click.stop="editItem(item)"
                class="ml-4"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>

              <v-spacer />

              <!-- <v-tooltip bottom transition="none">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    fab
                    depressed
                    color="info"
                    v-bind="attrs"
                    v-on="on"
                    @click="refreshItem(item)"
                  >
                    <v-icon>mdi-sync</v-icon>
                  </v-btn>
                </template>
                {{ $t('CREATIVE.REFRESH') }}
              </v-tooltip> -->

            </v-card-actions>
            <!-- <v-divider />
            <div class="text-small pa-2 pl-6">
              {{ $t("CHANNEL_SUBSCRIPTION.LAST_UPDATED") }}
              <br />
              <strong>{{ new Date(item.updated_at).toLocaleString() }}</strong>
            </div> -->
          </v-card>
      </template>

      <template v-slot:item-list="item">
        <div v-if="item.status_type" :class="['list-overlay', item.status_type ? item.status_type : '']"/>
        <v-list-item-content>
          <v-list-item-title class="primary--text">
            <strong>{{ item.title }}</strong>
          </v-list-item-title>
          <v-list-item-subtitle>
            <v-icon
              size="12"
              :color="item.status > 0 ? 'success' : 'error'"
              class="mr-2"
            >
              mdi-circle
            </v-icon>
            <v-chip
              x-small
              label
              class="pl-1 ml-1 text-capitalize"
              v-if="item.protocol"
            >
              <v-icon class="mr-1" size="14">mdi-protocol</v-icon>
              {{ item.protocol }}
            </v-chip>
            <v-chip
              x-small
              label
              class="pl-1 ml-1 text-capitalize"
              v-if="item.protocol"
            >
              <v-icon class="mr-1" size="14">mdi-file</v-icon>
              {{ item.fileformat }}
            </v-chip>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <status-label :item="item" />
        </v-list-item-action>
        <v-list-item-action class="ml-3 date">
          {{ $moment(item.updated_at).fromNow() }}
        </v-list-item-action>
        <v-list-item-action class="ml-2">
          <div>
            <v-btn icon @click.stop="removeItem(item)"><v-icon>mdi-delete</v-icon></v-btn>
            <v-btn icon @click.stop="editPermissionItem(item)"><v-icon>mdi-key-chain-variant</v-icon></v-btn>
            <v-btn icon @click.stop="editItem(item)"><v-icon>mdi-pencil</v-icon></v-btn>
          </div>
        </v-list-item-action>
      </template>

    </List>
  </div>
</template>


<script>

import PageHeader from '@/components/PageHeader'
// import Dialog from '@/components/Dialog'
import StatusLabel from '@/components/StatusLabel'
import Side from '@/components/Side'
import DialogRemove from '@/components/DialogRemove'
import List from '@/components/List'
import BannerEditorPreview from '@/forms/creative/BannerEditor.preview.vue'


const ELEMENT_NAME = 'creative'
const TABLE_NAME = 'creatives'
const SERVICE_NAME = 'api_programmatic'

const _formats = [ 'xml', 'csv', 'json' ]
const _statuses = [ 1, 0, -1 ]

export default {
  name: 'PageXprogrammaticAllCreatives',
  components: {
    PageHeader,
    // Dialog,
    Side,
    DialogRemove,
    List,
    StatusLabel,
    BannerEditorPreview
  },
  data: () => ({
    loading: false,
    ids: [],
    items: [],
    count: 0,
    offset: 0,
    limit: 100,
    page: 1,
    selected: [],
    showEdit: false,
    showNew: false,
    showDialogRemove: false,
    showDialogPermissionEdit: false,
    timeout: null,
    status: [1],
    fileformat: [],
    methods: [],
    startOfDay: new Date(`${new Date().toISOString().substr(0,10)  }T00:00:00.000Z`),
    endOfDay: new Date(`${new Date().toISOString().substr(0,10)  }T23:59:59.999Z`),
  }),
  props: {
    update:{
      type: [Number, String, Boolean, Object, Array],
      default: false
    },
    select:{
      type: [Number, String, Boolean, Object, Array],
      default: false
    },
  },

  watch: {
    update () {
      this.loadItems()
    },
    select (items) {
      this.selected = items
    },
    remove () {
      this.confirmRemoveItems()
    }
  },

  mounted () {
    this.$queryFilters().get()
    this.searchItems()
    if (this.itemId) {
      this.selectItems([{id: this.itemId}])
      this.editSelected()
    }
  },

  computed: {

    itemId () {
      return this.$router.currentRoute.query.edit || undefined
    },

    statuses () {
      return _statuses.map(value => ({
        value, text: this.$t(`INPUT.STATUS_${value === -1 ? '_1' : value}`),
      }))
    },

    formats () {
      return _formats.map(value => ({
        value,
        text: this.$t(`INPUT.FILEFORMAT_${value.toUpperCase()}`),
      }))
    },
  },

  methods: {

    clearFilters () {
      this.$queryFilters().clear()
      this.loadItems()
    },

    statusReportValue(name, item) {
      if (item.status_report && item.status_report[name]) {
        return Number(item.status_report[name]).toLocaleString()
      }
      return 0
    },

    // Request

    // search with a timeout
    searchItems(timeout = 150) {
      this.selected = []
      this.loading = true
      // debounce request
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loadItems()
      }, timeout)
    },

    // load items with filters
    loadItems (options = {}) {
      this.loading = true
      this.$queryFilters().save()

      // build query
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        mode: 'list',
        page: this.page || 1,
        limit: this.limit,
      }
      const query = this.$queryFormat({
        options: Object.assign({}, defaultOptions, options),
        where: {
          status: [1],
          job_count: {
            status: [0,1],
          },
        },
      })

      const handleResponse = (response) => {
        if (response.data) {
          this.items = response.data.items
          this.count = response.data.count
          this.offset = response.data.offset
          this.limit = response.data.limit
        } else {
          this.items = []
          this.count = 0
          this.offset = 0
          this.limit = 0
        }
        this.updateSelected()
      }

      // request
      this.$services[SERVICE_NAME][ELEMENT_NAME]
        .search(query)
        .then(response => {
          handleResponse(response)
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    changePage (page) {
      this.page = +page
      this.searchItems()
    },

    confirmRemoveItems () {
      const ids = this.selected.map(v => v.id)
      this.$services.api_programmatic.creative.remove({ where: { id: ids }, options: {limit:ids.length}})
        .then(response => {
          if (response.data) {
            this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
          }
          this.selected = []
          this.showDialogRemove = false
          this.loadItems()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
    },

    updateSelected () {
      for (let i = 0, lenI = this.selected.length; i < lenI; i++) {
        const a = this.selected[i]
        for (let j = 0, lenJ = this.items.length; j < lenJ; j++) {
          const b = this.items[j]
          if (a.id === b.id) {
            this.selected[i] = b
            break
          }
        }
      }
    },

    // Mutliple items

    removeSelected () {
      this.showDialogRemove = true
    },

    editSelected () {
      this.showEdit = true
      if (this.selected.length > 0) {
        const item = this.selected[0]
        this.$router.push(`/xprogrammatic/creatives/?edit=${  this.selected[0].id}`)
      }
    },

    selectItems (arr) {
      this.selected = arr
    },

    closeEdit () {
      this.showEdit = false
      this.$router.push('/xprogrammatic/creatives/')
    },

    // Single item

    updateItems () {
      this.loadItems()
    },

    newItem () {
      this.selected = []
      // this.showNew = true
      this.showEdit = true
    },

    refreshItem (item) {
      this.loading = true
      this.$services.api_programmatic.creative.refresh({where: {id:item.id}})
        .finally(() => {
          this.loading = false
          this.loadItems()
        })
    },

    editItem (item) {
      this.selected = [item]
      this.$router.push(`/xprogrammatic/creatives/?edit=${  this.selected[0].id}`)
      this.showEdit = true
    },

    editPermissionItem (item) {
      this.selected = [item]
      this.showDialogPermissionEdit = true
    },

    removeItem (item) {
      this.selected = [item]
      this.showDialogRemove = true
    },

    async removeItemFromCompact (data) {
      await this.$services.api_programmatic.creative.remove({ where: { id: data }})
      this.loadItems()
    },

    goToItem (item) {
      // item = item ? item : this.selected[0]
      // const route = `/xprogrammatic/creative/${  item.id}`
      // if (this.$router.currentRoute.path !== route) {
      //   this.$router.push(route)
      // }
    },
    // filter methods
    resetFilters () {
      this.applyFilters()
    },

    applyFilters () {
      this.searchItems()
    },
  }
}
</script>
